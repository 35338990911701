import * as React from "react";
import Layout from "../components/Layout"; 
import SEO from '../components/SEO'

const AccessibilityStatement = () => {
   
  return (
    <Layout bg={"gray"} header="light">
      <SEO
        title={"Accessibility Statement"}
        description={"LocalTier is committed"}
      /> 

      <section className="bg-white">
        <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 sm:py-12 lg:px-8 prose">
          <div className="py-4">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-display font-extrabold text-gray-900 mb-3">
            Accessibility Statement      </h1>
            
 <p className="text-gray-500 mb-6 leading-relaxed">

            LocalTier is committed to achieving the highest levels of satisfaction by making all users feel welcome, inlcuding users with disabilities. LocalTier is dedicated to offering a quality online experience on our website, localtier.com to all users.

            </p>


<h2 className="font-display ">Accessibility Questions/Feedback</h2>
<p className="text-gray-500 mb-6 leading-relaxed">


Please be aware our accessiblility efforts are still ongoing. 


If you are experiencing barriers accessing any of our digital content or notice anything that you believe is not fully accessible to people with disabilities, please email info@localtier.com with a description of your concerns. We appreciate all feedback and look forward to improving the LocalTier online experience for all users. 



</p>
          </div>
        </div>

      </section>





    </Layout>
  );
};

export default AccessibilityStatement;
